import React, { useState, useContext, createContext ,useEffect} from "react";
import { useLocalStorage } from "../hook/useLocalStorage";
export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [isOpenAgeGate, setIsOpenAgeGate] = useState(true);
  const [isOpenCookies, setIsOpenCookies] = useState(false);
  const [language, setLanguage] = useState('ee');
  const [value] = useLocalStorage("cookieOptions");
  const consentNeedAccepted = !value?.communication || !value?.performance;


  useEffect(() => {
    if(consentNeedAccepted) {
      setIsOpenCookies(true)
    }
  }, [consentNeedAccepted])

  const handleCloseCookies = () => {
    setIsOpenCookies(false);
  }

  const handleOpenCookieModal = () => {
    setIsOpenCookies(true);
  }

  const handleCloseAgeGate = () => {
    setIsOpenAgeGate(false);
  }

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleError = () => {
    setError(true);
  };

  const changeLang = (lang) => {
    setLanguage(lang);
  }

  return (
    <ModalContext.Provider
      value={{handleOpenCookieModal, isOpen, handleCloseModal, handleOpenModal, handleError, error,isOpenCookies,isOpenAgeGate,handleCloseAgeGate,handleCloseCookies, changeLang, language }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const ctx = useContext(ModalContext);
  if (ctx === undefined) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return ctx;
};

export default ModalProvider;
