import Modal from "react-modal";
import "./modal.scss";


const ReactModal = ({ children, isOpen }) => {
  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="AgeGateModal"
      className={{
        base: "modal",
        afterOpen: "modal_after-open",
        beforeClose: "modal_before-close",
      }}
      overlayClassName={{
        base: "overlay",
        afterOpen: "overlay_after-open",
        beforeClose: "overlay_before-close",
      }}
      closeTimeoutMS={0}
    >
      <div className="popup-inner">{children}</div>
    </Modal>
  );
};

export default ReactModal;
