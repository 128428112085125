import React, { useState } from "react";
import { useModalContext } from "../../context/ModalContext";
import PromoPopUp from "../../assets/img/Modal/PromoPopUp.png";
import "./promoModal.scss";

const PromoModal = ({ langData, closeModal }) => {
  const { error, handleError, handleClosePromoModal } = useModalContext();
  const PopUpImage = PromoPopUp;

  return (
    <>
        <button className="close-modal" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
        </button>
        <img src={PopUpImage} alt="promo" />
    </>
  );
};

export default PromoModal;