import veloLogoSvg from "../../assets/VeloPage/velo-logo.svg";
import { ReactComponent as LogoSvg } from "../../assets/logo/bat-logo.svg";
import { useModalContext } from "../../context/ModalContext";

const AgeGate = ({langData}) => {
  const { error, handleError,handleCloseAgeGate } = useModalContext();

  return (
    <div className="modal__ageGate">
      <div className="modal__ageGate__top">
        <img src={veloLogoSvg} alt="velo" />
      </div>
      <div className="modal__ageGate__content">
        <h2 className="title">{ langData.age_gate.title}</h2>
        <p>{ langData.age_gate.text}</p>
        <div className="modal__ageGate__btn-group">
          <button onClick={handleCloseAgeGate} className="modal__ageGate__btn">
          { langData.age_gate.yes }
          </button>
          <button onClick={handleError} className="modal__ageGate__btn">
          { langData.age_gate.no }
          </button>
        </div>
        {error && (
          <span className="modal__ageGate__error">
            Jums jābūt vimaz 18 gadiem, lai apmeklētu šo vietni.
          </span>
        )}
      </div>
      <div className="modal__ageGate__footer">
        <LogoSvg className="modal__ageGate__logo" />
        <p className="modal__ageGate__disclaimer">{ langData.age_gate.info }</p>
      </div>
    </div>
  );
};

export default AgeGate;
